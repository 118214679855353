import axios from 'axios';

import {PRODUCT_DETAIL, PRODUCT_FILE, PRODUCT_IMAGE, PRODUCTS, PRODUCT_DETAIL_ADMIN} from "../../constants/axiosURLs";

import {processProductData, processProductFiles, processProductPictures} from "./process";

// import data from "../data/physical_list";

export function getProductsList(thenFunc, catchFunc) {
    // let fakeResponse = {data: data};
    // thenFunc(fakeResponse);

    axios.get(PRODUCTS, { withCredentials: true })
        .then(thenFunc)
        .catch(catchFunc);
}

export function getProductDetail(productId, thenFunc, catchFunc) {
    // let fakeResponse = {data:
    //         data.find( item => ''+item.id === productId )};
    //
    // thenFunc(fakeResponse);

    axios.get(PRODUCT_DETAIL_ADMIN(productId,thenFunc), { withCredentials: true })
        .then(thenFunc)
        .catch(catchFunc);
}

export function addProduct(product) {
    let thenFunc = () => {
        alert("Товар добавлен");
        window.location.href = "/products/product-list";
    };

    let pictureCatchFunc = () =>
        alert("Товар добавлен, но не удалось прикрепить изображения к нему");

    let fileCatchFunc = () =>
        alert("Товар с изображениями добавлен, но не удалось прикрепить дополнительные файлы к нему");

    axios.post(PRODUCTS, processProductData(product), { withCredentials: true })
        .then( (response) => {
            addProductPictures(response.data.id,
                product.newPictures,
                () => {
                    addProductFiles(response.data.id,
                        product.newFiles,
                        thenFunc, fileCatchFunc);
                }, pictureCatchFunc);
        } )

        .catch( () => alert("Не удалось добавить товар") );
}

export function addProductPictures(productId, files, thenFunc, catchFunc) {
    axios.post(PRODUCT_IMAGE(productId), processProductPictures(files), { withCredentials: true })
        .then(thenFunc)
        .catch(catchFunc);
}

export function addProductFiles(productId, files, thenFunc, catchFunc) {
    axios.post(PRODUCT_FILE(productId), processProductFiles(files), { withCredentials: true })
        .then(thenFunc)
        .catch(catchFunc);
}

export function editProduct(product) {
    let thenFunc = () => {
        alert("Изменения сохранены");
        window.location.href = "/products/product-list";
    };

    let pictureCatchFunc = () =>
        alert("Изменения сохранены, но не удалось прикрепить новые изображения к нему");

    let fileCatchFunc = () =>
        alert("Изменения сохранены, но не удалось прикрепить дополнительные файлы к нему");

    axios.put(PRODUCT_DETAIL(product.id), processProductData(product), { withCredentials: true })
        .then( (response) => {
            addProductPictures(response.data.id,
                product.newPictures,
                () => {
                    addProductFiles(response.data.id,
                        product.newFiles,
                        thenFunc, fileCatchFunc);
                }, pictureCatchFunc);
        } )
        .catch( () => alert("Не удалось сохранить изменения") );
}

export function deleteProduct(productId, thenFunc) {
    // eslint-disable-next-line no-restricted-globals
    let isDelete = confirm("Вы уверены, что хотите удалить товар?");

    if (isDelete) {
        let catchFunc = () =>
            alert("Не удалось удалить товар");

        axios.delete(PRODUCT_DETAIL(productId), { withCredentials: true })
            .then(thenFunc)
            .catch(catchFunc);
    }
}