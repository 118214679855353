import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import {getOrderDetail, getOrderStatuses} from "../../assets/axios/orders";
import {THEME_COLOR} from "../../constants/other";
import Loader from "react-loader-spinner";
import {formatDate, getPaymentLink} from "../../assets/js/functions";
import OrderItems from "./OrderItems";
import DeliveryDetail from "./DeliveryDetail";
import Modal from 'react-responsive-modal';
import OrderStatuses from "./OrderStatuses";
import UserDetail from './UserDetail';
import CommentForm from "./CommentForm/index";

export class Order_detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalContent: '',
      detail: null,
      status: null
    }

    this.changeDetail = this.changeDetail.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }

  componentDidMount() {
    getOrderDetail(this.props.orderId,
      this.changeDetail,
      () => alert("Не удалось загрузить информацию о заказе"));

    getOrderStatuses(response => {
      this.setState({
        modalContent: <OrderStatuses statuses={response.data}
                                     orderId={this.props.orderId}
                                     onChange={this.changeStatus}/>
      });
    })
  }

  changeDetail(response) {
    this.setState({
      detail: response.data,
      status: response.data.status
    });
  }

  changeStatus(status) {
    this.setState({
      status: status,
      modalOpen: false
    });
  }

  onOpenModal = () => {
    this.setState({modalOpen: true});
  };
  onCloseModal = () => {
    this.setState({modalOpen: false});
  };

  render() {
    let {detail, status} = this.state;
    console.log(this.state.detail);
    return (
      this.state.detail
        ?
        <Fragment>
          <Breadcrumb title="Детали заказа"/>

          <div className="container-fluid">
            <div className="card">
              <div className="row product-page-main card-body">
                <div className="col-xl-12">
                  <div className="product-page-details product-right mb-0">
                    <div className={"obot-order-detail-header"}>
                      <div>
                        <h2>Заказ № {detail.id}</h2>
                        <p>{formatDate(detail.time)}</p>
                      </div>
                      <div>
                        <div className={"order-status-mark clickable"}
                             style={{background: status.color}}
                             onClick={this.onOpenModal}>
                          {status.name}
                        </div>
                      </div>
                    </div>
                    <OrderItems items={detail.orderItems} delivery={detail.delivery}/>
                    <hr/>
                    <div className={'product-wrapper row'}>
                      <div className={'col-lg-6 col-md-12'}>
                        <UserDetail user={detail.user} comment={detail.comment}/>
                        <hr/>
                        <DeliveryDetail detail={detail.delivery}/>
                        <hr className={'hr'}/>
                      </div>
                      <CommentForm id={detail.id} comment={detail.comments} update={() => getOrderDetail(this.props.orderId, this.changeDetail)}/>

                    </div>
                    {
                      status.name === 'не оплачен'
                        ? <button className={"btn btn-primary obot-repayment"}
                                  onClick={() => window.open(getPaymentLink(this.state.detail),
                                    '_blank')}>
                          Ссылка на повторную оплату
                        </button>
                        : ""
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
          <Modal open={this.state.modalOpen} onClose={this.onCloseModal} center>
            {this.state.modalContent}
          </Modal>
        </Fragment>
        : <Loader type="Hearts"
                  width={50} height={50}
                  color={THEME_COLOR}
                  className={"obot-loader"}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orderId: ownProps.match.params.id,
  }
}

export default connect(mapStateToProps)(Order_detail);