import axios from 'axios'
import {CREATE_COMMENT,COMMENT} from '../../constants/axiosURLs'

export function createComment(orderId, comment, thenFunc) {
  axios.post(CREATE_COMMENT(orderId), {text: comment}, {withCredentials:true})
    .then(thenFunc)
    .catch(() => console.log('Не удалось отправить комментарий'))
}

export function editComment(orderId, comment, thenFunc) {
  axios.patch(COMMENT(orderId), {text: comment}, {withCredentials:true})
    .then(thenFunc)
    .catch(() => 'Не удалось обновить комментарий')
}

export function deleteComment(orderId, thenFunc) {
  axios.delete(COMMENT(orderId))
    .then(thenFunc)
    .catch(() => console.log('Произошла ошибка при удалении комментария'))
}