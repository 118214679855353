import React, {Component} from 'react'

export class User_detail extends Component {
  render() {
    let {user, comment} = this.props
    return (
      <div className={'product-component'}>
        <h1 className={'product-title-main'}>Информация о заказчике</h1>
        <h6 className="product-title">Имя покупателя</h6>
        <p>{user.name}</p>
        <h6 className="product-title">Телефон</h6>
        <p>{user.phone}</p>
        <h6 className="product-title">Email</h6>
        <p>{user.email}</p>
        {
          comment
            ? <>
              <h6 className="product-title">Комментарий к заказу</h6>
              <p>{comment}</p>
            </>
            : ""
        }
      </div>
    )
  }
}

export default User_detail
