import React from 'react';
import {shortenString} from "../js/functions";
import {Edit, Trash2} from "react-feather";

export const categoryColumns = (onEdit, onDelete) => [
    {
        Header: <b>Название</b>,
        accessor: "name",
        Cell: row => shortenString(row.original.name, 20),
        style: {
            textAlign: 'center'
        }
    },
    {
        Header: <b>Действие</b>,
        accessor: "action",
        Cell: row => <div className={"table-toolbar"}>
            <div className={"edit"}>
                <div onClick={ () => onEdit(row.original.id, row.original.name) }>
                    <Edit/></div>
            </div>
            <div className={"delete"}>
                <div onClick={ () => onDelete(row.original.id) }>
                    <Trash2/></div>
            </div>
        </div>,
        style: {
            textAlign: 'center'
        }
    }
];

export const data = [
    {
        id: 1,
        name: "категория №1"
    },
    {
        id: 2,
        name: "категория №2"
    },
    {
        id: 3,
        name: "категория №3"
    },
    {
        id: 4,
        name: "категория №4"
    },
    {
        id: 5,
        name: "категория №5"
    },
    {
        id: 6,
        name: "дефолтная категория"
    }
];