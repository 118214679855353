import React, {Component, Fragment} from 'react';
import {Tabs, TabList, TabPanel, Tab} from 'react-tabs';
import {User} from 'react-feather';
import {withRouter} from 'react-router-dom';
import {checkAuth} from "../../assets/axios/auth";

export class LoginTabset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }

        this.auth = this.auth.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    clickActive = (event) => {
        document.querySelector(".nav-link")
            .classList.remove('show');
        event.target.classList.add('show');
    }

    handleEmailChange(event) {
        this.setState({
            email: event.target.value
        });

        event.preventDefault();
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value
        });

        event.preventDefault();
    }

    auth(event) {
        checkAuth(this.state);
        event.preventDefault();
    }

    render() {
        return (
            <div>
                <Fragment>
                    <Tabs>
                        <TabList className="nav nav-tabs tab-coupon">
                            <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><User/>Вход</Tab>
                        </TabList>

                        <TabPanel>
                            <form className="form-horizontal auth-form" onSubmit={this.auth}>
                                <div className="form-group">
                                    <input required={true} name="email" type="email"
                                           className="form-control" placeholder="Логин"
                                           value={this.state.email} onChange={this.handleEmailChange}/>
                                </div>
                                <div className="form-group">
                                    <input required={true} name="password" type="password"
                                           className="form-control" placeholder="Пароль"
                                           value={this.state.password} onChange={this.handlePasswordChange}/>
                                </div>

                                {/*Забыли пароль*/}

                                <div className="form-button">
                                    <button className="btn btn-primary"
                                            type="submit">
                                        Войти
                                    </button>
                                </div>
                            </form>
                        </TabPanel>
                    </Tabs>
                </Fragment>
            </div>
        )
    }
}

export default withRouter(LoginTabset)

