export const SERVER_HOST = "/";

export const ADMIN_SESSION = `${SERVER_HOST}api/admin/session`;
export const ADMIN_INFO = `${SERVER_HOST}api/admin`;

export const PRODUCTS = `${SERVER_HOST}api/product`;
export const PRODUCT_IMAGE = (id) => `${SERVER_HOST}api/product/${id}/image`;
export const PRODUCT_FILE = (id) => `${SERVER_HOST}api/product/${id}/file`;
export const PRODUCT_DETAIL_ADMIN = (id) => `${SERVER_HOST}api/admin/product/${id}`;
export const PRODUCT_DETAIL = (id) => `${SERVER_HOST}api/product/${id}`;

export const CATEGORIES = `${SERVER_HOST}api/category`;
export const CATEGORY = (id) => `${SERVER_HOST}api/category/${id}`;

export const ORDERS = `${SERVER_HOST}api/order`;
export const ORDER_DETAIL = (id) => `${SERVER_HOST}api/order/${id}`;
export const ORDER_STATUSES = `${SERVER_HOST}api/status`;
export const ORDER_STATUS = (orderId, statusId) => `${SERVER_HOST}api/order/${orderId}/status/${statusId}`;

export const CREATE_COMMENT = (id) => `${SERVER_HOST}api/admin/order/${id}/comment`;
export const COMMENT = (id) => `${SERVER_HOST}api/admin/comment/${id}`;

export const SUPPLIERS = `${SERVER_HOST}api/supplier`