import * as types from "../../constants/actionsTypes/products";
import {getProductsList} from "../../assets/axios/products";

function updateProductsList(list) {
    return {
        type: types.UPDATE_PRODUCTS_LIST,
        value: list
    };
}

export function loadProductsList(list = null) {
    if (list) {
        return (dispatch) => {
            dispatch( updateProductsList(list) );
        }
    } else {
        return (dispatch) => {
            getProductsList(
                (response) => dispatch( updateProductsList(response.data) ),
                () => {
                    alert("Не удалось загрузить список товаров");
                    dispatch( updateProductsList([]) );
                });

        }
    }
}