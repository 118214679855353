import React, {Component} from 'react';
import emptyProduct from "../../assets/images/image-stub.jpg";
import {Link} from "react-router-dom";
import {SYMBOL} from "../../constants/other";

export class OrderItems extends Component {
    render() {
        let { items, delivery } = this.props;
        return (
            <div className="col-sm-12">
                <table className="table obot cart-table table-responsive-xs">
                    <thead>
                    <tr className="table-head">
                        <th scope="col">продукт</th>
                        <th scope="col">наименование</th>
                        <th scope="col">цена</th>
                        <th scope="col">количество</th>
                        <th scope="col">итого</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item) => {
                        let { product } = item;
                        return (
                            <tr key={item.id}>
                                <td>
                                    <Link to={`/products/product-detail/${product.id}`}>
                                        <img src={product.pictures.length ? (product.pictures[0].url) : emptyProduct}
                                             className={"obot-product-picture"}
                                             alt="" />
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/products/product-detail/${product.id}`}>
                                        {product.name}
                                    </Link>
                                </td>
                                <td>{ product.price } { SYMBOL }</td>
                                <td>
                                    { item.count }
                                </td>
                                <td>{ item.count * product.price } {SYMBOL}</td>
                            </tr>
                        )
                    })}

                    <tr>
                        <td>
                        </td>
                        <td>
                            Доставка
                        </td>
                        <td>{ delivery.cost } { SYMBOL }</td>
                        <td>
                        </td>
                        <td>{ delivery.cost } {SYMBOL}</td>
                    </tr>

                    </tbody>
                </table>
                <hr/>
                <h3 className="product-title total">ИТОГО : {
                    items.reduce((acc, item) =>
                        (acc + item.count * item.product.price), 0)
                    + delivery.cost
                } {SYMBOL}</h3>
            </div>
        )
    }
}

export default OrderItems