import React, {Component} from 'react';
import {Trash2} from "react-feather";

class ExistingProductPictures extends Component {
    render() {
        let { pictures, onDelete } = this.props;
        return (
            pictures.length
            ? <div className={"row existing-pictures-container"}>
                    {
                        pictures.map((pic) =>
                            <div key={pic.id} className={"col-sm-3 col-6"}>
                                <div className={"delete-button"}
                                     onClick={() => onDelete(pic)}><Trash2/></div>
                                <img src={pic.url} alt={pic.id} className={"img-fluid"}/>
                            </div>
                        )
                    }
                </div>
            : ""
        )
    }
}

export default ExistingProductPictures