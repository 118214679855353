import axios from 'axios';
import {ADMIN_INFO, ADMIN_SESSION} from "../../constants/axiosURLs";

export function checkAuth(userData) {
    axios.post(ADMIN_SESSION, userData, { withCredentials: true })
        .then(function (response) {
            window.location.href = '/dashboard';
        })
        .catch(function () {
            alert("Не удалось осуществить вход");
        });
}

export function getUserInfo(thenFunc, catchFunc) {
    axios.get(ADMIN_INFO, { withCredentials: true })
        .then(thenFunc)
        .catch(catchFunc);
}