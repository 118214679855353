import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.css';


export class Datatable extends Component {
    render() {
        const { pageSize, myClass, pagination } = this.props;

        return (
            <Fragment>
                <ReactTable
                    data={this.props.myData}
                    columns={this.props.columns}
                    defaultPageSize={pageSize}
                    className={myClass}
                    showPagination={pagination}
                />
            </Fragment>
        )
    }
}

export default Datatable
