export function processProductData(product) {
    let complectationItems = product.complectationItems
        .filter(({ name, value }) => name && value);

    let characteristicItems = product.characteristicItems
        .filter(({ name, value }) => name && value);

    let processedProduct = Object.assign({}, product,
        product.size, { complectationItems, characteristicItems });

    delete processedProduct.size;

    processedProduct.category = processedProduct.category || null;

    return  processedProduct;
}

export function processProductPictures(pictures) {
    let formData = new FormData();

    pictures.forEach((item) => {
        formData.append("pictures[]", item);
    });

    return formData;
}

export function processProductFiles(files) {
    let formData = new FormData();

    files.forEach((item) => {
        formData.append("files[]", item);
    });

    return formData;
}