import {
    Home,
    Box,
    DollarSign,
    Clipboard,
    LogIn,
    List,
    Plus, Grid
} from 'react-feather';

export const MENUITEMS = [
    {
        path: '/dashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
    },
    {
        path: '/orders/order-list', title: 'Заказы', icon: DollarSign, type: 'link', active: false
    },
    {
        title: 'Товары', icon: Box, type: 'sub', active: false, children: [
            { path: '/products/product-list', title: 'Список товаров', icon: List, type: 'link' },
            { path: '/products/add-product', title: 'Добавить товар', icon: Plus, type: 'link' },
        ]
    },
    {
        path: '/categories/category-list', title: 'Категории', icon: Grid, type: 'link', active: false
    },
    {
        title: 'Новости', icon: Clipboard , type: 'sub', active: false, children: [
            { path: '/pages/list-page', title: 'Список новостей', icon: List, type: 'link' },
            { path: '/pages/create-page', title: 'Создать новость', icon: Plus, type: 'link' },
        ]
    },
    {
        title: 'Выход', path:'/auth/login', icon: LogIn, type: 'link', active: false
    }
]
