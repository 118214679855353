import React from 'react';
import ReactDOM from 'react-dom';
import Root from "./root";

import Provider from "react-redux/es/components/Provider";
import {store} from "./redux/StoreInit";

import './index.scss';

let app =
    <Provider store={store}>
        <Root/>
    </Provider>;

ReactDOM.render(app, document.getElementById('root'));