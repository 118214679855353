import React, {Component} from 'react';
import {changeOrderStatus} from "../../assets/axios/orders";

export class OrderStatuses extends Component {
    render() {
        let { statuses, orderId, onChange } = this.props;
        return (
            <div className={"obot-statuses-container"}>
                {
                    statuses.map(status =>
                        <div className={"order-status-mark clickable"}
                             style={{ background: status.color }}
                             key={status.id}
                             onClick={ () =>
                                 changeOrderStatus(orderId,
                                     status.id,
                                     () => onChange(status)) }>
                            { status.name }
                        </div>
                    )
                }
            </div>
        )
    }
}

export default OrderStatuses