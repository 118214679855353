import React, { Component,Fragment } from 'react'

import Breadcrumb from '../common/breadcrumb';
import {SYMBOL} from "../../constants/other";

import { Edit, Trash2 } from 'react-feather'

import {connect} from "react-redux";
import {loadProductsList} from "../../redux/actions/products";

import emptyProduct from "../../assets/images/image-stub.jpg";
import {Link} from "react-router-dom";
import {deleteProduct} from "../../assets/axios/products";
import SearchHeader from "../common/header_components/searchHeader";
import {PRODUCTS} from "../../constants/axiosURLs";

export class Product_list extends Component {
    componentDidMount() {
        this.props.loadProductsList();
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Список товаров" parent={"Dashboard"} />
                <div className="container-fluid">
                    <SearchHeader url={PRODUCTS} callback={this.props.loadProductsList} />
                    <div className="row products-admin ratio_asos">
                        {
                            this.props.list.map((item) => {
                                return (
                                    <div className="col-xl-3 col-sm-6" key={item.id}>
                                        <div className="card">
                                            <div className="products-admin">
                                                <div className="card-body product-box">
                                                    <div className="img-wrapper">
                                                        <div className="lable-block">
                                                            {(item.new )?<span className="lable3">new</span> : ''}
                                                            {(item.discount)?<span className="lable4">on sale</span> : '' }
                                                            </div>
                                                        <div className="front">
                                                            <a className="bg-size">
                                                                <img className="img-fluid blur-up bg-img lazyloaded"
                                                                     src={item.pictures.length
                                                                         ? item.pictures[0].url
                                                                         : emptyProduct}
                                                                     alt={item.name} />
                                                            </a>
                                                            <div className="product-hover">
                                                                <ul>
                                                                    <li>
                                                                        <button className="btn" type="button">
                                                                            <Link to={`/products/edit-product/${item.id}`}>
                                                                                <Edit className="editBtn" />
                                                                            </Link>
                                                                        </button>
                                                                    </li>
                                                                    <li>
                                                                        <button className="btn" type="button"
                                                                                onClick={() => deleteProduct( item.id,
                                                                                    () => this.props.loadProductsList() )}>
                                                                            <Trash2 className="deleteBtn" />
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-detail">
                                                        <Link to={`/products/product-detail/${item.id}`}><h6 >{item.name}</h6></Link>
                                                        <h4 >
                                                            {item.discount
                                                                ? <div>
                                                                    { Math.round(item.price * (100 - item.discount) / 100) } {SYMBOL} <del>
                                                                    {item.price} {SYMBOL}</del>
                                                                 </div>
                                                                : <div>{item.price} {SYMBOL}</div>}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default connect(state => state.products, { loadProductsList }) (Product_list)
