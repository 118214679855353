import * as types from "../../constants/actionsTypes/categories";
import {getCategoriesList} from "../../assets/axios/categories";

function updateCategoriesList(list) {
    return {
        type: types.UPDATE_CATEGORIES_LIST,
        value: list
    };
}

export function loadCategoriesList(list = null) {
    if (list) {
        return (dispatch) => {
            dispatch( updateCategoriesList(list) );
        }
    } else {
        return (dispatch) => {
            getCategoriesList(
                (response) => {
                    dispatch(updateCategoriesList(response.data))
                },
                () => {
                    alert("Не удалось загрузить список категорий");
                    dispatch( updateCategoriesList([]) );
                });
        }
    }
}