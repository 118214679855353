import thunk from 'redux-thunk'
import {createStore, applyMiddleware} from 'redux';
import reducer from "./reducers";

export const INITIAL_STORE_STATE =
    {
        products: {
            list: []
        },
        categories: {
            list: []
        },
        user: null,
        orders: {
            list: []
        }
    };

export const store = applyMiddleware(thunk)(createStore)(reducer);