import React, {Component, Fragment} from 'react';
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import {connect} from "react-redux";
import {loadCategoriesList} from "../../redux/actions/categories";
import {categoryColumns} from "../../assets/data/categories";
import {addCategory, deleteCategory, editCategory} from "../../assets/axios/categories";
import Modal from "react-responsive-modal";

class CategoryList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openModal: false,
            currentCategory: '',
            currentCategoryId: null,
            new: false
        };

        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onCategoryInputChange = this.onCategoryInputChange.bind(this);
    }

    componentDidMount() {
        this.props.loadCategoriesList();
    }

    onDeleteClick() {
        let self = this;
        return function(categoryId) {
            deleteCategory(categoryId, () => {
                self.props.loadCategoriesList();
            });
        }
    }

    onEditClick() {
        let self = this;
        return function(categoryId, categoryName) {
            self.setState({
                currentCategory: categoryName,
                currentCategoryId: categoryId
            }, () => {
                self.onOpenModal(false)
            });
        }
    }

    onEdit(event) {
        event.preventDefault();
        editCategory(this.state.currentCategoryId,
            { name : this.state.currentCategory },
            () => {
                this.onCloseModal();
                this.props.loadCategoriesList();
            })
    }

    onAdd(event) {
        event.preventDefault();
        addCategory({ name : this.state.currentCategory }, () => {
            this.onCloseModal();
            this.props.loadCategoriesList();
        });
    }

    onCategoryInputChange(event) {
        this.setState({
            currentCategory: event.target.value
        });
    }

    onOpenModal = (isNew) => {
        this.setState({
            openModal: true,
            new: isNew }
            );
    };

    onCloseModal = () => {
        this.setState({
            openModal: false,
            currentCategory: '',
            currentCategoryId: null
        });
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Список категорий" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable">
                                    <div className="row">
                                        <div className="col-12 button-bar">
                                            <button className="btn btn-primary"
                                                    onClick={() => this.onOpenModal(true)}>
                                                Добавить категорию
                                            </button>
                                        </div>
                                    </div>
                                    <Datatable
                                        multiSelectOption={false}
                                        columns={categoryColumns(this.onEditClick(), this.onDeleteClick())}
                                        myData={this.props.list}
                                        pageSize={10}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal open={this.state.openModal} onClose={this.onCloseModal} center>
                    <div className="obot-modal">
                        <form onSubmit={this.state.new ? this.onAdd : this.onEdit}>
                            <div className="form-group">
                                <label className="col-form-label pt-0">Название категории</label>
                                <input className="form-control"
                                       required={true}
                                       type="text"
                                       value={this.state.currentCategory}
                                       onChange={this.onCategoryInputChange} />
                            </div>
                            <button className="btn btn-primary center"
                                    type={"submit"}>
                                {this.state.new ? "Добавить" : "Сохранить"}
                            </button>
                        </form>
                    </div>
                </Modal>
            </Fragment>
        )
    }
}

export default connect(state => state.categories, { loadCategoriesList }) (CategoryList)